import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { useExperiments } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import type { FC } from 'react';
import React from 'react';

import type { ControllerProps } from '../../../types';
import { Experiment } from '../../../types';

const FormikWidget = loadable(() => {
  return import(/* webpackChunkName: "RootWidget" */ './FormikWidget/Widget');
});

const Widget = loadable(() => {
  return import(
    /* webpackChunkName: "RootWidget" */ './ReactHookFormWidget/Widget'
  );
});

const Root: FC<WidgetProps<ControllerProps | undefined>> = (
  controllerProps,
) => {
  const { experiments } = useExperiments();
  if (!controllerProps.isRendered) {
    return null;
  }

  if (experiments.enabled(Experiment.EnableReactHookForm)) {
    return <Widget {...controllerProps} />;
  }

  return <FormikWidget {...controllerProps} />;
};

export default Root;
